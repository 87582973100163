import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import { SEO, mapToSEO } from '@components/SEO'

import { ImageInformationList, mapToInfoBannerContainer } from '@components/ImageInformationList'
import { mapToImageInfoBanner, ImageInformationBanner } from "@components/ImageInformationBanner"
import { FaqList, mapToFaqContainer } from "@components/FaqList"
import { LaybyCalculator } from "@components/LaybyCalculator"
import { mapToPlateFormatList } from "@mappers/index"
import { mapToPageHeader, Banner } from "@components/Banner"
import { mapToInfoImageSliderItem } from "@components/InfoImageSlider/mapper"
import InfoImageSliderItem from "@components/InfoImageSlider/InfoImageSliderItem"
import { ImageButtonBanner, mapToImageLinkBanner } from "@components/ImageButtonBanner"
import { SemanticColumns } from "@utils/KontentTypes"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import { Responsive, Grid } from "semantic-ui-react"
import theme from "@styles/theme"

interface Props {
  data: any;
}

class LaybyLandingPage extends React.Component<Props>{

  render() {
    const { elements } = this.props.data.kontentItemLaybyPage;
    const apiUrl = this.props.data.site.siteMetadata.apiUrl;
    const { image_info_list_1 } = elements;
    const metadata = mapToPageHeader(elements);
    const { layby_faqs, image_banner, layby_calculator, image_info_banner, image_info_block_list_2, image_info_button_banner } = elements;
    const seoData = mapToSEO(elements);
    const imageInfoList1Data = mapToInfoBannerContainer(image_info_list_1.linked_items[0]?.elements)
    const infoBannerData = mapToImageInfoBanner(image_banner.linked_items[0]?.elements)
    const laybyFaqData = mapToFaqContainer(layby_faqs.linked_items[0].elements)
    const laybyCalculatorData = mapToPlateFormatList(layby_calculator.linked_items[0].elements.product_category_formats)
    const imageInfoSliderData = mapToInfoImageSliderItem(image_info_banner.linked_items[0]?.elements)
    const imageInfoList2Data = mapToInfoBannerContainer(image_info_block_list_2.linked_items[0]?.elements)
    const imageInfoButtonBanner = mapToImageLinkBanner(image_info_button_banner.linked_items[0]?.elements)

    return (<Layout version="standard">
      <SEO {...seoData} />
      <Banner {...metadata} isBiggerBanner={false} />
      <Responsive {...Responsive.onlyComputer}>
        <GenericBannerContainer
          padding={{
            mobile: {
              top: 80,
              bottom: 80
            },
            desktop: {
              top: 100,
              bottom: 100
            }
          }}
          backgroundColor={infoBannerData.backgroundColor}>
          <ImageInformationBanner {...infoBannerData} />
        </GenericBannerContainer>
      </Responsive>
      <GenericBannerContainer
        padding={{
          mobile: {
            top: 60,
            bottom: 60
          },
          desktop: {
            top: 80,
            bottom: 80
          }
        }}
        backgroundColor='#f9f9f9'>
        <Grid centered>
          <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={12} widescreen={12}>
            <LaybyCalculator apiUrl={apiUrl} key={2} calculatorOptions={[]} plateFormats={laybyCalculatorData} />
          </Grid.Column>
        </Grid>
      </GenericBannerContainer>
      <GenericBannerContainer
        padding={{
          mobile: {
            top: 60,
            bottom: 60
          },
          desktop: {
            top: 80,
            bottom: 80
          }
        }}
        backgroundColor={imageInfoList1Data.backgroundColor}>
        <ImageInformationList
          listItems={imageInfoList1Data.listItems}
          backgroundColor={imageInfoList1Data.backgroundColor}
          textAlign={imageInfoList1Data.textAlign}
          columns={imageInfoList1Data.listItems.length as SemanticColumns}
          horizontalLayout={false}
          titleOverlayImage={false}
          titleColor={imageInfoList1Data.textColor}
          textColor={imageInfoList1Data.textColor} />
      </GenericBannerContainer>
      <GenericBannerContainer padding={{ mobile: { top: 50, bottom: 60 }, desktop: { top: 90, bottom: 100 } }} backgroundColor={laybyFaqData.backgroundColor}>
        <FaqList {...laybyFaqData} answerColor={theme.brand.colors.white} questionColor={theme.brand.colors.white} borderColor={theme.brand.colors.white} />
      </GenericBannerContainer>
      <InfoImageSliderItem {...imageInfoSliderData} />
      <GenericBannerContainer
        padding={{
          mobile: {
            top: 60,
            bottom: 80
          },
          desktop: {
            top: 80,
            bottom: 100
          }
        }}
        backgroundColor={imageInfoList2Data.backgroundColor}>
        <ImageInformationList
          listItems={imageInfoList2Data.listItems}
          backgroundColor={imageInfoList2Data.backgroundColor}
          textAlign={imageInfoList2Data.textAlign}
          columns={4}
          horizontalLayout={false}
          titleOverlayImage={false}
          titleColor={imageInfoList2Data.textColor}
          textColor={imageInfoList2Data.textColor} />
      </GenericBannerContainer>
      {
        imageInfoButtonBanner &&
        <ImageButtonBanner {...imageInfoButtonBanner} />
      }
    </Layout>
    );
  }
}

export const query = graphql`{
  
  site {
    siteMetadata {
      apiUrl
    }
  }
  kontentItemLaybyPage {
    elements {
      seo__page_title {
        value
      }
      seo__page_description {
        value
      }
      seo__noindex {
        value {
          name
        }
      }
      seo__nofollow {
        value {
          name
        }
      }
      url {
        value
      }
      image_info_list_1 {
        linked_items {
          ... on KontentItemInfoBannerContainer {
            elements {
              background_colors {
                value {
                  name
                  codename
                }
              }
              text_colors {
                value {
                  codename
                }
              }
              items {
                linked_items {
                  ... on KontentItemInfoBannerItem {
                    elements {
                      description {
                        value
                      }
                      icon {
                        value {
                          url
                          name
                        }
                      }
                      linktext {
                        value
                      }
                      linkurl {
                        value
                      }
                      title {
                        value
                      }
                    }
                  }
                }
              }
              text_alignment {
                value {
                  codename
                  name
                }
              }
              text_colors {
                value {
                  codename
                  name
                }
              }
            }
          }
        }
      }
      layby_calculator {
        linked_items {
          ... on KontentItemLaybyCalculator {
            elements {
              product_category_formats {
                linked_items {
                  ... on KontentItemProductCategoryFormats {
                    elements {
                      format_type {
                        value {
                          codename
                          name
                        }
                      }
                      base_price {
                        value
                      }
                      description {
                        value
                      }
                      example_image {
                        value {
                          url
                        }
                      }
                      title {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      layby_faqs {
        linked_items {
          ... on KontentItemFaqBlock {
            elements {
              background_colors {
                value {
                  codename
                  name
                }
              }
              faqs {
                linked_items {
                  ... on KontentItemFaqItem {
                    elements {
                      answer {
                        value
                      }
                      faq_category {
                        value {
                          codename
                          name
                        }
                      }
                      question {
                        value
                      }
                    }
                  }
                }
              }
              title {
                value
              }
            }
          }
        }
      }
      image_info_block_list_2 {
        linked_items {
          ... on KontentItemInfoBannerContainer {
            elements {
              background_colors {
                value {
                  codename
                  name
                }
              }
              text_colors {
                value {
                  codename
                }
              }
              text_alignment {
                value {
                  codename
                  name
                }
              }
              items {
                linked_items {
                  ... on KontentItemInfoBannerItem {
                    elements {
                      description {
                        value
                      }
                      icon {
                        value {
                          url
                        }
                      }
                      linktext {
                        value
                      }
                      linkurl {
                        value
                      }
                      title {
                        value
                      }
                    }
                  }
                }
              }
              text_colors {
                value {
                  codename
                  name
                }
              }
            }
          }
        }
      }
      general_page_content__responsive_header_image {
        linked_items {
          ... on KontentItemBannerImage {
            id
            elements {
              desktop {
                value {
                  url
                  description
                }
              }
              mobile {
                value {
                  description
                  url
                }
              }
              tablet {
                value {
                  description
                  url
                }
              }
            }
          }
        }
      }
      image_info_button_banner {
        linked_items {
          ... on KontentItemImageLinkBanner {
            elements {
              button__button_color_theme {
                value {
                  codename
                  name
                }
              }
              button__button_type {
                value {
                  codename
                  name
                }
              }
              button__text {
                value
              }
              button__url_slug {
                value
              }
              image {
                value {
                  url
                }
              }
              title {
                value
              }
            }
          }
        }
      }
      image_info_banner {
        linked_items {
          ... on KontentItemInfoImageSliderItem {
            elements {
              button__button_color_theme {
                value {
                  codename
                  name
                }
              }
              button__button_type {
                value {
                  codename
                  name
                }
              }
              button__text {
                value
              }
              button__url_slug {
                value
              }
              description {
                value
              }
              image {
                value {
                  url
                }
              }
              name {
                value
              }
            }
          }
        }
      }
      image_banner {
        linked_items {
          ... on KontentItemInfoImageBanner {
            elements {
              background_colors {
                value {
                  codename
                  name
                }
              }
              text_colors {
                value {
                  name
                  codename
                }
              }
              description {
                value
              }
              image {
                value {
                  url
                }
              }
              info_image_banner___image_position {
                value {
                  codename
                  name
                }
              }
              title {
                value
              }
            }
          }
        }
      }
      general_page_content__header_image {
        value {
          url
        }
      }
      general_page_content__responsive_header_image {
        linked_items {
          ... on KontentItemBannerImage {
            id
            elements {
              desktop {
                value {
                  url
                  description
                }
              }
              mobile {
                value {
                  description
                  url
                }
              }
              tablet {
                value {
                  description
                  url
                }
              }
            }
          }
        }
      }
      general_page_content__kiwivip_signup {
        value {
          codename
          name
        }
      }
      general_page_content__kiwivip_signup_version {
        value {
          codename
          name
        }
      }
      general_page_content__title {
        value
      }
      general_page_content__title_position {
        value {
          codename
          name
        }
      }
    }
  }
}

`

export default LaybyLandingPage
